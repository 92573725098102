<template transition="scale-transition" origin="center center">
  <v-container fill-height >
    <v-row align="center" justify="center">
      <v-col cols="12" class="ma-0 py-0 pl-4 pr-0" >
        <v-container class="pt-0" >
          <v-row class="actions__container">
            <v-col cols="2" class="pa-2">
              <v-switch
                v-model="active"
                inset
                :label="
                  active
                    ? $t('components.views.settings.posting_rules.automatic_publications.disable')
                    : $t('components.views.settings.posting_rules.automatic_publications.active')
                "
              />
            </v-col>
            <v-spacer />
          </v-row>
          <v-row>
            <v-col cols="12" class="pt-0">
              <div class="pb-1 mb-1">
                <v-row class="actions__add">
                  <v-col cols="8" md="auto" justify-center class="mt-0">
                    <h3 class="secondary--text">
                      {{
                        $t(
                          "components.views.settings.posting_rules.automatic_publications.intervals"
                        )
                      }}
                    </h3>
                  </v-col>
                  <v-spacer />
                  <v-col cols="1">
                    <v-tooltip bottom>
                      <template #activator="{ on, attrs }">
                        <v-btn
                          color="primary"
                          justify-center
                          depressed
                          block
                          style="width: 50px;"
                          v-bind="attrs"
                          v-on="on"
                          @click="addFields('fieldsFroms')"
                          :disabled="lengthFieldsFroms >= 5"
                        >
                          <v-icon size="16">fa-solid fa-plus</v-icon>
                        </v-btn>
                      </template>
                      <span>{{
                        $t(
                          "components.views.settings.posting_rules.automatic_publications.add_interval"
                        )
                      }}</span>
                    </v-tooltip>
                  </v-col>
                </v-row>
              </div>
            </v-col>
          </v-row>
          <div id="container_posting_rules" class="scroll-design pa-0">
            <v-container class="px-0 ma-0 py-4" >
              <div v-for="(fieldFrom, a) in fieldsFroms" :key="'field' + a" class="px-5 pt-4 pr-8 fields__container" >
                <v-row>
                  <v-col cols="12" class="pa-0 fields__actions">
                    <b class="primary--text pl-3">
                      {{
                        $t(
                          "components.views.settings.posting_rules.automatic_publications.interval"
                        )
                      }}
                      N° {{ a + 1 }}
                    </b>
                    <v-spacer />
                    <v-col cols="1" class="pa-0 ma-0" >
                      <v-tooltip bottom>
                        <template #activator="{ on, attrs }">
                          <v-btn
                            color="red darken-2 white--text"
                            class="btn-removefields"
                            depressed
                            dense
                            block
                            v-bind="attrs"
                            v-on="on"
                            @click="removeFields(a)"
                            :disabled="fieldsFroms.length < 2"
                          >
                            <v-icon size="16">fa-solid fa-minus</v-icon>
                          </v-btn>
                        </template>
                        <span>{{
                          $t(
                            "components.views.settings.posting_rules.automatic_publications.delete_interval"
                          )
                        }}</span>
                      </v-tooltip>
                    </v-col>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="auto" class="pb-0 mb-0">
                    <p class="mt-2 mb-0">
                      {{
                        $t(
                          "components.views.settings.posting_rules.automatic_publications.published_time"
                        )
                      }}:
                    </p>
                  </v-col>
                  <v-col cols="2" class="pb-0">
                    <v-text-field
                      v-model="fieldFrom.valueDayTimePublic"
                      outlined
                      required
                      :label="
                        $t('components.views.settings.posting_rules.automatic_publications.days')
                      "
                      dense
                      v-integer
                    />
                  </v-col>
                  <v-col cols="2" class="pb-0">
                    <v-text-field
                      v-model="fieldFrom.valueHourTimeDate"
                      outlined
                      required
                      dense
                      :label="
                        $t('components.views.settings.posting_rules.automatic_publications.hours')
                      "
                    />
                  </v-col>
                  <v-spacer />

                </v-row>
                <v-row>
                  <v-col cols="12" class="py-0">
                    <SelectAutocomplete
                      v-model="fieldFrom.transportCompanies"
                      return-object
                      dense
                      :data="dataBusiness"
                      :selected="fieldFrom.transportCompanies"
                      item-text="businessName"
                      item-value="idCompany"
                      :label="
                        $t(
                          'components.views.settings.posting_rules.automatic_publications.company_transporter'
                        )
                      "
                      :placeholder="
                        $t(
                          'components.views.settings.posting_rules.automatic_publications.company_transporter'
                        )
                      "
                      :rules="[
                        required(
                          $t(
                            'components.views.settings.posting_rules.automatic_publications.company_transporter'
                          ),
                          1
                        ),
                      ]"
                      :init="false"
                      chips
                      required
                      multiple
                      v-bind="$attrs"
                      v-on="$listeners"
                    >
                      <template v-slot:prepend-item>
                        <v-list-item ripple @mousedown.prevent @click="toggle(a)">
                          <v-list-item-action>
                            <v-icon :class="icon" />
                          </v-list-item-action>
                          <v-list-item-content>
                            <v-list-item-title>
                              {{
                                $t(
                                  "components.views.settings.posting_rules.automatic_publications.select_all"
                                )
                              }}
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>


                       <!--  <v-divider class="mt-2"></v-divider> -->



                      </template>
                      <template v-slot:selection="{ attrs, item, parent, selected }">
                        <v-chip v-bind="attrs" :input-value="selected" label small>
                          <span class="pr-2">
                            {{ item.businessName }}
                          </span>
                          <v-icon small @click="parent.selectItem(item)"> $delete </v-icon>
                        </v-chip>
                      </template>
                    </SelectAutocomplete>
                  </v-col>
                </v-row>

              </div>
            </v-container>
          </div>
          <v-card-actions class="border-top my-0">
            <v-spacer />
            <v-btn color="light-green darken-3" depressed class="white--text" @click="save">
              <v-icon small class="fa-solid fa-bookmark mr-1" />
              {{ $t("components.views.settings.posting_rules.automatic_publications.save") }}
            </v-btn>
          </v-card-actions>
        </v-container>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import validate from "@/plugins/validate";
export default {
  data() {
    return {
      ...validate,
      active: false,
      fieldsFroms: [],
      update: false,
      companys: 0,
      dataBusiness: [],
      fieldFrom: {
        transportCompanies: [],
      },
    };
  },
  computed: {
    ...mapGetters({
      user: "auth/user",
      search: "search/search",
    }),
    idCompany() {
      if (this.user !== undefined) {
        let userMap = JSON.parse(this.user);
        return userMap.user.idCompany;
      }
    },
    likesAllBusiness() {
      return this.companys.length === this.dataBusiness.length;
    },
    likesSomeBusiness() {
      return this.companys.length > 0 && this.likesAllBusiness;
    },
    icon() {
      if (this.likesAllBusiness) return "fa-solid fa-square-check";
      if (this.likesSomeBusiness) return "fa-solid fa-square-minus";
      return "fa-solid fa-square";
    },

    lengthFieldsFroms() {
      return this.fieldsFroms.length;
    },
  },
  watch: {
    companys(newVal) {
      if (newVal.length == 0) {
        this.companys = 0;
      }
    },
  },
  mounted() {
    this.loadBusiness();
  },
  metaInfo() {
    return {
      title: this.$t(
        "components.views.settings.posting_rules.automatic_publications.automatic_publications"
      ),
    };
  },
  methods: {
    ...mapActions("snackbar", ["showSnack"]),
    ...mapActions("loader", ["showLoader"]),

    async save() {
      this.showLoader({
        show: true,
      });

      let params = this.fieldsFroms.map((element) => {
        let timePublic = element.valueDayTimePublic * 24 + parseInt(element.valueHourTimeDate);
        return {
          timePublic,
          transportCompanies: element.transportCompanies,
        };
      });

      let paramsN = {
        id: this.idCompany,
        active: this.active,
        intervals: params,
      };
      if (!this.update) {
        await this.axios
          .post(`/RulesPublication/Save`, paramsN)
          .then(() => {
            this.showSnack({
              text: this.$t(
                "components.views.settings.posting_rules.automatic_publications.success_save"
              ),
              title: this.$t(
                "components.views.settings.posting_rules.automatic_publications.success"
              ),
              name: "success",
            });
            this.$router.push({ name: "TripNoAssign" });
          })
          .catch((error) => {
            this.showSnack({
              text: error.response.data,
              title: "Error",
              name: "error",
            });
          })
          .finally(() => {
            this.showLoader({
              show: false,
            });
          });
      } else {
        await this.axios
          .post(`/RulesPublication/Update`, paramsN)
          .then(() => {
            this.showSnack({
              text: this.$t(
                "components.views.settings.posting_rules.automatic_publications.success_save"
              ),
              title: this.$t(
                "components.views.settings.posting_rules.automatic_publications.success"
              ),
              name: "success",
            });
            this.$router.push({ name: "TripNoAssign" });
          })
          .catch((error) => {
            this.showSnack({
              text: error.response.data,
              title: "Error",
              name: "error",
            });
          })
          .finally(() => {
            this.showLoader({
              show: false,
            });
          });
      }
    },

    toggle(index) {
      this.$nextTick(() => {
        this.companys = this.likesAllBusiness ? [] : this.dataBusiness.slice();
        let businessMap = this.companys.map((element) => {
          return {
            name: element.businessName,
            idCompany: element.idCompany,
          };
        });
        this.fieldsFroms[index].transportCompanies = [...businessMap];
      });
    },

    addFields() {
      this.fieldsFroms.push({
        valueDayTimePublic: 0,
        valueHourTimeDate: 0,
        valueTimePublic: null,
        transportCompanies: [],
      });
    },

    removeFields(index) {
      this.fieldsFroms.splice(index, 1);
    },

    async loadBusiness() {
      await this.axios
        .get(`/TransportCompany/`)
        .then((response) => {
          this.dataBusiness = response.data ? response.data : [];
        })
        .catch((error) => {
          this.showSnack({
            text: error.response.data,
            title: "Error",
            name: "error",
          });
        });

      this.loadData();
    },

    async loadData() {
      let data = null;
      await this.axios
        .get(`RulesPublication/Get/${this.idCompany}`)
        .then((response) => {
          if (response) {
            data = response.data;
            if (data !== undefined) {
              this.active = data.active;
              this.update = true;
              if (data.intervals !== undefined) {
                this.fieldsFroms = data.intervals.map((element) => {
                  let valueDayTimePublic = Math.floor(parseInt(element.timePublic) / 24);
                  let valueHourTimeDate = element.timePublic - 24 * valueDayTimePublic;
                  return {
                    valueDayTimePublic,
                    valueHourTimeDate,
                    transportCompanies: element.transportCompanies,
                  };
                });
              }
            }
          }
        })
        .catch((error) => {
          this.showSnack({
            text: error.response.data,
            title: "Error",
            name: "error",
          });
        });
    },
  },
};
</script>

<style scoped>
@import './AutomaticPublicationsStyle.scss';
</style>
